// Font Size

@for $i from 0 through 6 {
  @for $j from 0 through 9 {
    @if $j ==0 {
      .font-size-#{$i} {
        font-size: (($i + ($j * 0.1)) * 1rem) !important;
      }
    }

    @else {
      .font-size-#{$i}-#{$j} {
        font-size: (($i + ($j * 0.1)) * 1rem) !important;
      }
    }
  }
}

// Line height

@for $i from 1 through 6 {
  @for $j from 0 through 9 {
    @if $j ==0 {
      .line-height-#{$i} {
        line-height: (($i + ($j * 0.1)) * 1rem) !important;
      }
    }

    @else {
      .line-height-#{$i}-#{$j} {
        line-height: (($i + ($j * 0.1)) * 1rem) !important;
      }
    }
  }
}

// Font Weight

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

// Padding

@for $i from 0 through 40 {
  .p-#{$i} {
    padding: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 30 {
  .pt-#{$i} {
    padding-top: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 30 {
  .pr-#{$i} {
    padding-right: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 30 {
  .pb-#{$i} {
    padding-bottom: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 30 {
  .pl-#{$i} {
    padding-left: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 50 {
  .px-#{$i} {
    padding-right: ($i * 0.1rem) !important;
    padding-left: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 50 {
  .py-#{$i} {
    padding-top: ($i * 0.1rem) !important;
    padding-bottom: ($i * 0.1rem) !important;
  }
}

// Margin

@for $i from 0 through 40 {
  .m-#{$i} {
    margin: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 40 {
  .mt-#{$i} {
    margin-top: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 40 {
  .mr-#{$i} {
    margin-right: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 40 {
  .mb-#{$i} {
    margin-bottom: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 40 {
  .ml-#{$i} {
    margin-left: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 50 {
  .mx-#{$i} {
    margin-right: ($i * 0.1rem) !important;
    margin-left: ($i * 0.1rem) !important;
  }
}

@for $i from 0 through 50 {
  .my-#{$i} {
    margin-top: ($i * 0.1rem) !important;
    margin-bottom: ($i * 0.1rem) !important;
  }
}

// Border-radius

.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}

.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

// .border-light-5 {
//   border: $border-width solid rgba($border-light-color, .5) !important;
// }

// Height

@for $i from 1 through 50 {
  .h-#{$i} {
    height: ($i * 0.1rem) !important;
  }
}

@for $i from 11 through 80 {
  .h-#{$i * 5} {
    height: ($i * 0.5rem) !important;
  }
}

.h-auto {
  height: auto !important;
}

.h-100p {
  height: 100% !important;
}

// Width

@for $i from 1 through 50 {
  .w-#{$i} {
    width: ($i * 0.1rem) !important;
  }
}

@for $i from 11 through 80 {
  .w-#{$i * 5} {
    width: ($i * 0.5rem) !important;
  }
}

.w-auto {
  width: auto !important;
}

.w-100p {
  width: 100% !important;
}

.w-70p {
  width: 70% !important;
}

.w-50p {
  width: 50% !important;
}

.w-40p {
  width: 40% !important;
}

// Flex

@for $i from 1 through 10 {
  .flex-#{$i} {
    flex: $i;
  }
}

// Grid

// @for $i from 1 through 12 {
//   .col-#{$i} {
//     flex: 0 0 ((100% / 12) * $i);
//     max-width: (100% / 12) * $i;
//   }
// }

.single-col {
  flex: 0 0 100%;
  max-width: 100%;
}

// Z-index
@for $i from 10 through 300 {
  @if ($i % 10==0) {
    .z-#{$i} {
      z-index: $i !important;
    }
  }
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}





.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.d-flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.flex-grow-3 {
  flex-grow: 3 !important;
}

.flex-grow-4 {
  flex-grow: 4 !important;
}

.flex-grow-5 {
  flex-grow: 5 !important;
}

.flex-grow-6 {
  flex-grow: 6 !important;
}

.flex-grow-7 {
  flex-grow: 7 !important;
}

.flex-grow-8 {
  flex-grow: 8 !important;
}

.flex-grow-9 {
  flex-grow: 9 !important;
}

.flex-grow-10 {
  flex-grow: 10 !important;
}

.flex-basis-1 {
  flex-basis: 1rem !important;
}

.flex-basis-2 {
  flex-basis: 2rem !important;
}

.flex-basis-3 {
  flex-basis: 3rem !important;
}

.flex-basis-4 {
  flex-basis: 4rem !important;
}

.flex-basis-5 {
  flex-basis: 5rem !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.border-radius-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-radius-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-radius-top-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.border-radius-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.text-white {
  color: $color-white !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.m-auto {
  margin: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.bw-1-5 {
  border-width: 0.15rem !important;
}

.d-none {
  display: none;
}

.object-fit-cover {
  object-fit: cover;
}

.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-x: hidden;
  overflow-y: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.pos-top-0 {
  top: 0 !important;
}

.pos-right-0 {
  right: 0 !important;
}

.pos-bottom-0 {
  bottom: 0 !important;
}

.pos-left-0 {
  left: 0 !important;
}


// phone - 600px
@media (max-width: 37.5em) {
  .flex-column-phone {
    flex-direction: column !important;
  }

  .flex-row-phone {
    flex-direction: row !important;
  }

  .justify-content-end-phone {
    justify-content: flex-end !important;
  }
}

// tab-port - //900px
@media (max-width: 56.25em) {
  .flex-column-tab-port {
    flex-direction: column !important;
  }

  .flex-row-tab-port {
    flex-direction: row !important;
  }
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.position-initial {
  position: initial !important;
}

.phone-d-flex {
  display: none !important;

  @include respond(phone) {
    display: flex !important;
  }
}

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.4rem;

  @include respond(phone) {
    grid-template-columns: repeat(1, 1fr);
  }
}