@import "./_variables.scss";
@import "./_functions.scss";
@import "./theme/theme-style.scss";

@font-face {
  font-family: "Myriad Pro";
  src: local("Myriad Pro"),
    url("/assets/fonts/Myriad Pro/Myriad Pro Regular.ttf") format("truetype");
  font-display: swap;
}

/*
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 10px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: 0;
}*/
.cust-form-field-container {
  padding-bottom: 10px;
}

.cust-form-field {
  width: 100%;
  margin-bottom: -0.75rem;
}

.cust-error-text {
  font-size: 14px;
}

.cust-darker-shadow {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4) !important;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4) !important;
}

.p-r-40 {
  padding-left: 40px;
}
.p-l-40 {
  padding-left: 40px;
}

.myriad-font {
  font-family: "Myriad Pro" !important;
}

.blue-background {
  background-color: #2841a5 !important;
  color: white !important;
}

.blue-background .mat-form-field-label {
  color: white !important;
}

.pink-background {
  background-color: #ff62b1 !important;
  color: white !important;
}

.pink-background .mat-form-field-label {
  color: white !important;
}

.cust-img-max-width {
  max-width: 100%;
}

.cust-text-truncate {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::ng-deep {
  .blue-background .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white !important;
  }
  .blue-background mat-form-field {
    .mat-hint,
    input,
    ::placeholder,
    .mat-form-field-label {
      color: white !important;
    }
  }
}

::ng-deep .blue-background .mat-form-field-underline,
::ng-deep .blue-background .mat-form-field-ripple {
  background-color: white !important;
}

::ng-deep {
  .pink-background .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white !important;
  }
  .pink-background mat-form-field {
    .mat-hint,
    input,
    ::placeholder,
    .mat-form-field-label {
      color: white !important;
    }
  }
}

::ng-deep .pink-background .mat-form-field-underline,
::ng-deep .pink-background .mat-form-field-ripple {
  background-color: white !important;
}

.mat-card-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  object-fit: cover;
}