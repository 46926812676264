*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;

  @include respond(tab-land) {
    //font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
    font-size: 45%;
  }

  @include respond(tab-port) {
    //font-size: 50%; //1rem = 8px, 8/16 = 50%
    font-size: 35%;
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12px, 12/16 = 75%
  }

  @include respond(phone) {
    font-size: 60%; //1rem = 6px, 6/16 = 37.5%
  }
}

body {
  background-color: #e9edf2;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
  overflow: hidden;
}

a {
  text-decoration: none;
}