@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";

@import "base/base";
@import "base/helpers";

@import "components/components";

@import "layout/header";

.main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
}

.top-nav {
    height: 6.5rem;
    flex-shrink: 0;
    background-color: $color-primary;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.2rem;

    &__logo {
        display: flex;
        align-items: center;

        &--symbol {
            width: 3.6rem;
        }

        &--text {
            width: 9.1rem;
            margin-left: 1.7rem;
        }
    }

    &__menu {
        color: $color-white;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        font-size: 2rem;
        margin-right: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond(tab-port) {
            display: flex;
        }
    }

    &__user {
        display: flex;
        align-items: center;

        &--avatar {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            margin-left: 1.7rem;
            border: none;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &--btn {
            background: none;
            color: $color-white;
            border: none;
            font-size: 1.6rem;
            padding: 0;
            margin: 0;
            cursor: pointer;
            transition: .5s;
            position: relative;

            &:hover {
                color: rgba($color-white, .8);
            }

            .count {
                position: absolute;
                top: -.5rem;
                right: -.5rem;
                background: #d71920;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Poppins", sans-serif;
                font-size: 1.1rem;
                font-weight: 500;
                color: $color-white;
            }
        }
    }
}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 1rem;
    position: relative;

    &__left-nav {
        width: 27rem;
        flex-shrink: 0;
        background-color: $color-white;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        overflow: auto;
        transition: .5s;

        @include respond(phone) {
            position: absolute;
            top: 0;
            left: -100%;
            bottom: 0;
            width: 100%;
            z-index: 100;
        }

        @include respond(tab-port) {
            position: absolute;
            top: 0;
            left: -100%;
            bottom: 0;
        }

        &.show {
            @include respond(phone) {
                left: 0;
            }

            @include respond(tab-port) {
                left: 0;
            }
        }

        &--item {
            padding-left: 2.2rem;
            position: relative;

            &.sub-menu {
                &::before {
                    content: '';
                    position: absolute;
                    right: 1rem;
                    top: 1.7rem;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    background-color: rgba(233, 237, 242, .5);
                    z-index: 20;
                    transition: .5s;
                }

                &::after {
                    font-family: 'bsmartlytics' !important;
                    font-size: 1rem;
                    color: $color-grey;
                    content: "\e913";
                    position: absolute;
                    right: 1.5rem;
                    top: 2.25rem;
                    z-index: 30;
                    transition: .5s;
                }
            }

            .sub-menu__content {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 0;
                overflow: hidden;
                transition: .5s;

                &::before {
                    content: '';
                    position: absolute;
                    top: 1.6rem;
                    left: 1.3rem;
                    bottom: 1.6rem;
                    width: .1rem;
                    background-color: #ededee;
                    z-index: 20;
                }

                &--item {
                    padding-left: 4.6rem;
                    position: relative;

                    .sub-btn {
                        font-family: "Poppins", sans-serif;
                        font-size: 1.4rem;
                        color: $color-grey;
                        font-weight: 400;
                        width: 100%;
                        height: 3.5rem;
                        background: none;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        transition: .5s;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 1.5rem;
                        left: 1.1rem;
                        width: .5rem;
                        height: .5rem;
                        border-radius: 50%;
                        background-color: rgba(77, 81, 90, .25);
                        z-index: 25;
                    }

                    &.active {
                        .sub-btn {
                            font-weight: 500;
                            color: $color-primary;
                        }

                        &::before {
                            background-color: $color-primary;
                        }
                    }

                    &:hover {
                        .sub-btn {
                            color: $color-primary;
                        }

                        .sub-menu {
                            &::before {
                                background-color: rgba(233, 237, 242, 1.75);
                            }
                        }
                    }
                }
            }

            &.open {
                .sub-menu__content {
                    height: auto;
                }

                &.sub-menu {
                    &::before {
                        background-color: rgba(233, 237, 242, .75);
                    }

                    &::after {
                        top: 2.05rem;
                        transform: rotate(180deg);
                    }
                }
            }

            .nav-btn {
                font-family: "Poppins", sans-serif;
                color: $color-grey;
                width: 100%;
                height: 5.5rem;
                background: none;
                border: none;
                outline: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                text-decoration: none;
                transition: .5s;
                position: relative;
                z-index: 35;

                &__icon {
                    display: flex;
                    width: 2.6rem;
                    height: 5.5rem;
                    margin-right: 2rem;
                    align-items: center;
                    justify-content: center;
                }

                &__text {
                    font-size: 1.6rem;
                    font-weight: 600;
                    color: $color-grey;
                }

                &:hover {
                    color: $color-primary;

                    .nav-btn__text {
                        color: $color-primary;
                    }
                }

                &.active {
                    color: $color-primary;

                    .nav-btn__text {
                        color: $color-primary;
                    }
                }
            }
        }
    }

    &__content {
        flex-grow: 1;
        padding: 2.4rem;
        overflow: auto;
    }
}

.bg-color-1 {
    background-color: #e7e1fb;
}

.text-color-1 {
    color: #795bc2;
}

.bg-color-2 {
    background-color: #fff0e1;
}

.text-color-2 {
    color: #de9d51;
}

.bg-color-3 {
    background-color: #e8f3e9;
}

.text-color-3 {
    color: #68a26d;
}

.bg-color-4 {
    background-color: #eef5f9;
}

.text-color-4 {
    color: #6b91a9;
}

.bg-color-5 {
    background-color: #fbefca;
}

.text-color-5 {
    color: #bf9a25;
}

.bg-color-6 {
    background-color: #ddfad9;
}

.text-color-6 {
    color: #66c55b;
}

.bg-color-7 {
    background-color: #f2e7de;
}

.text-color-7 {
    color: #9e6739;
}

.bg-color-8 {
    background-color: #dff6f6;
}

.text-color-8 {
    color: #4b9e9e;
}

.bg-color-9 {
    background-color: #f6ddf3;
}

.text-color-9 {
    color: #b560ab;
}

.bg-color-10 {
    background-color: #f1fac2;
}

.text-color-10 {
    color: #727d36;
}

.bg-color-11 {
    background-color: #daecdc;
}

.text-color-11 {
    color: #5fa867;
}

.bg-color-12 {
    background-color: #e7e1fb;
}

.text-color-12 {
    color: #7860ae;
}

.bg-color-13 {
    background-color: #fff0e1;
}

.text-color-13 {
    color: #a7702f;
}

.bg-color-14 {
    background-color: #e8f3e9;
}

.text-color-14 {
    color: #437d48;
}

.bg-color-15 {
    background-color: #eef5f9;
}

.text-color-15 {
    color: #547d97;
}

.bg-color-16 {
    background-color: #fbefca;
}

.text-color-16 {
    color: #d0ab35;
}

.bg-color-17 {
    background-color: #ddfad9;
}

.text-color-17 {
    color: #66c55b;
}

.bg-color-18 {
    background-color: #f2e7de;
}

.text-color-18 {
    color: #bc8b62;
}

.bg-color-19 {
    background-color: #dff6f6;
}

.text-color-19 {
    color: #4b9e9e;
}

.bg-color-20 {
    background-color: #f6ddf3;
}

.text-color-20 {
    color: #b560ab;
}

.summary-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.6rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }

    &__item {
        min-height: 13.5rem;
        border-radius: 1.2rem;
        padding: 2.4rem;

        &--head {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 2rem;

            .icon {
                width: 4rem;
                height: 4rem;
                border-radius: 1.4rem;
                background-color: $color-white;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
            }

            .title {
                font-size: 1.4rem;
                font-weight: 700;
                color: #202020;
                margin-left: 1.6rem;
                line-height: normal;
            }
        }

        &--body-text {
            font-size: 1.8rem;
            font-weight: 400;
            color: #101010;
            line-height: 1;
        }

        &--body-list {
            font-size: 1.5rem;
            font-weight: 400;
            color: #101010;
            line-height: 1;
            list-style: none;
            padding: 0;
            margin: 0;

            li+li {
                margin-top: .6rem;
            }
        }
    }
}

.outcomes-charts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.4rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }

    .chart {
        // height: 40rem;

        .content-box__title {
            line-height: 1.2;
        }

        .content-box__btn {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 0;
            margin: 0;
            color: #8da0ae;
            transition: .5s;

            &:hover {
                color: $color-primary;
            }
        }
    }
}

.clinician-form {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2.4rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}


/*******************************************************/
#snav .mat-nav-list .mat-list-item>.mat-list-item-content>a {
    font-family: "Poppins", sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    color: #4d515a;
    height: 5.5rem;
    margin-bottom: 0;
    padding: 0;
}

#snav .mat-nav-list .mat-list-item.selected>.mat-list-item-content>a {
    font-family: "Poppins", sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    color: #4d515a;
    background: none;
}

.mat-drawer-side {
    border-right: none;
}

.form-box__body .mat-radio-group .option-radio-container .option-radio {
    margin-bottom: 1rem !important;
}

.form-box__body .mat-radio-group .option-radio-container:last-child .option-radio {
    margin-bottom: 0 !important;
}

.form-box__body .mat-radio-label-content {
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    color: #101010;
}

.form-box__body .mat-radio-container {
    width: 1.8rem;
    height: 1.8rem;
}

.form-box__body .mat-radio-outer-circle {
    background-color: $color-white;
    width: 1.8rem;
    height: 1.8rem;
    border: 0.2rem solid #5a5a5a;
}

.form-box__body .mat-radio-inner-circle {
    top: 0;
    left: 0;
    height: 1.8rem;
    width: 1.8rem;
}

.form-box__body .mat-radio-label {
    height: 1.8rem;
}


.form-box__body .option-checkbox-container .option-checkbox {
    margin-bottom: 1rem !important;
}

.form-box__body .option-checkbox-container:last-child .option-checkbox {
    margin-bottom: 0 !important;
}

.form-box__body .mat-checkbox-label {
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    color: #101010;
    line-height: 1.8rem;
}

.form-box__body .mat-checkbox-container {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 0;
    margin-bottom: 0;
}

.form-box__body .mat-checkbox-inner-container {
    background-color: $color-white;
    width: 1.8rem;
    height: 1.8rem;
    // border: 0.2rem solid #5a5a5a;
}

// .form-box__body .mat-checkbox-inner-circle {
//     top: 0;
//     left: 0;
//     height: 1.8rem;
//     width: 1.8rem;
// }

// .form-box__body .mat-checkbox-label {
//     height: 1.8rem;
// }

apx-chart {
    position: inherit !important;
}

/*
.apexcharts-canvas {
    position: inherit !important;
}

.apexcharts-toolbar {
    padding: 0 !important;
    top: 2.4rem !important;
    right: 2.4rem !important;
}

.apexcharts-menu-icon {
    transform: scale(1) !important;
    color: #8da0ae !important;
}
*/

.mat-form-field-outline {
    background-color: #ffffff;
    border-radius: 4px;
}

.mat-select-placeholder {
    font-size: 1.4rem;
}

.mat-option-text {
    font-size: 1.4rem;
}

.dashboard-cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }

    .content-box {
        min-height: 30rem;
    }
}

.form-box-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.4rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }

    &__column {
        .form-box:last-child {
            margin-bottom: 0;
        }
    }
}

.custom-mat-checkbox {
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    color: #101010;
    line-height: 1.8rem;
}

.multiselect-dropdown .dropdown-btn {
    background-color: #ffffff;
    font-size: 1.4rem;
}

.section-title-bar {
    background-color: darken($color-tertiary, 0%);
    height: 4.8rem;
    padding: 0 2.4rem;
    border-radius: 1.2rem;
    color: $color-white;
    display: flex;
    align-items: center;

    .content-box__title {
        color: $color-white;
    }
}

.content-box.chart .content-box__title {
    font-size: 2rem;
}

.summary-filter-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.mat-card .mat-card-content {
    font-size: 1.4rem;
}

.mat-checkbox {
    font-size: 15px;
}

.mat-form-field-flex {
    font-size: 15px;
}

.mat-drawer-container {
    font-size: 14px;
}

.service-demand-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.4rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.hr-planning-management-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.four-column-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.content-box__title.sub {
    font-size: 1.6rem !important;
    color: #ffffff;
    font-weight: 500;
    padding-left: 2.4rem;
    margin-bottom: 1.6rem;
}

.login-main {
    background-color: #ffffff;
    width: 100%;
    height: 100vh;

    @include respond(phone) {
        overflow: auto;
    }
}

.login-content {
    min-height: 100vh;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;

    @include respond(phone) {
        flex-direction: column;
        min-height: auto;
    }

    // &.register {
    //     height: 10rem;
    //     overflow: auto;

    //     @include respond(phone) {
    //         height: auto;
    //     }
    // }

    &__bg {
        flex-grow: 1;
        background: url("~src/assets/images/login-register.jpg") no-repeat calc(50% - 200px) center;
        background-size: cover;
        background-position: center;

        @include respond(phone) {
            height: 30rem;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &__form {
        background-color: #ffffff;
        width: 100%;
        max-width: 40rem;
        flex-shrink: 0;
        font-size: 16px;
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: auto;

        @include respond(phone) {
            max-width: 100%;
            flex-grow: 1;
            height: auto;
        }
    }

    &__logo {
        width: 20rem;
        align-self: center;
        margin-top: 2.5rem;
        margin-bottom: 5rem;
    }
}

html .page-wrapper .page-content:has(app-user-list) {
    height: 100%;
}

app-user-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 34.5rem) !important;
        overflow-y: auto;

        @include respond(phone) {
            height: calc(100vh - 39rem) !important;
        }
    }

    .ngx-datatable .datatable-footer .datatable-footer-inner {
        @include respond(phone) {
            height: auto !important;
        }
    }
}

app-clinician-patient-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 34.5rem) !important;
        overflow-y: auto;

        @include respond(phone) {
            height: calc(100vh - 39rem) !important;
        }
    }

    .ngx-datatable .datatable-footer .datatable-footer-inner {
        @include respond(phone) {
            height: auto !important;
        }
    }
}

app-kin-patient-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 34.5rem) !important;
        overflow-y: auto;

        @include respond(phone) {
            height: calc(100vh - 39rem) !important;
        }
    }

    .ngx-datatable .datatable-footer .datatable-footer-inner {
        @include respond(phone) {
            height: auto !important;
        }
    }
}

app-invitation-list-admin {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 34.5rem) !important;
        overflow-y: auto;

        @include respond(phone) {
            height: calc(100vh - 39rem) !important;
        }
    }

    .ngx-datatable .datatable-footer .datatable-footer-inner {
        @include respond(phone) {
            height: auto !important;
        }
    }
}

app-auditlytics-permission-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 29rem) !important;
        overflow-y: auto;

        @include respond(phone) {
            height: calc(100vh - 34rem) !important;
        }
    }

    .ngx-datatable .datatable-footer .datatable-footer-inner {
        @include respond(phone) {
            height: auto !important;
        }
    }
}

app-suppression-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 37rem) !important;
        overflow-y: auto;
    }
}

app-art-code-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 37rem) !important;
        overflow-y: auto;
    }
}

app-mutation-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 37rem) !important;
        overflow-y: auto;
    }
}

app-drug-interaction-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 37rem) !important;
        overflow-y: auto;
    }
}

app-adverse-event-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 37rem) !important;
        overflow-y: auto;
    }
}

app-feedback-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 31rem) !important;
        overflow-y: auto;
    }
}

app-survey-result-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 31rem) !important;
        overflow-y: auto;
    }
}

app-subscription-plan-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 37rem) !important;
        overflow-y: auto;
    }
}

app-dhis2-main {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 31rem) !important;
        overflow-y: auto;
    }
}

app-openmrs-main {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 31rem) !important;
        overflow-y: auto;
    }
}

app-individual-patient-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 34rem) !important;
        overflow-y: auto;
    }
}

app-individual-clinician-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 34rem) !important;
        overflow-y: auto;
    }
}

app-organisation-clinician-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 38rem) !important;
        overflow-y: auto;
    }
}

app-organisation-patient-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 34rem) !important;
        overflow-y: auto;
    }
}

app-organisation-auditlytics-permission-list {
    .ngx-datatable.scroll-horz .datatable-body {
        height: calc(100vh - 30rem) !important;
        overflow-y: auto;
    }
}

html .page-wrapper .page-content {
    @include respond(phone) {
        padding: 1.6rem;
    }

    .mat-form-field-infix {
        @include respond(phone) {
            border-top: none;
        }
    }
}

.ngx-datatable.material .datatable-footer {
    @include respond(phone) {
        margin-bottom: .8rem;
    }
}

.ngx-datatable.material .datatable-footer .datatable-pager {
    @include respond(phone) {
        margin: 0;
    }
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
    @include respond(phone) {
        margin: 0;
    }
}