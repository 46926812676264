.content-box {
    background-color: $color-white;
    border-radius: 1.2rem;
    padding: 2.4rem;
    position: relative;

    @include respond(phone) {
        padding: 1.6rem;
    }

    &__title {
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 2.4rem;
        color: #1f1f1f;
    }
}

.art-table {
    width: 100%;

    thead {
        tr {
            line-height: 1;

            th {
                font-family: "Poppins", sans-serif;
                font-size: 1.4rem;
                font-weight: 700;
                color: #101010;
                padding-bottom: 1.4rem;
            }
        }
    }

    tbody {
        td {
            font-family: "Poppins", sans-serif;
            font-size: 1.3rem;
            font-weight: 400;
            color: #303030;
            height: 4rem;
            background-color: #e9edf2;
            padding-left: 2rem;

            @include respond(phone) {
                min-width: 15rem;
                white-space: nowrap;
                padding-right: 2rem;
            }
        }

        tr td:first-child {
            font-weight: 600;
            color: #101010;
            background-color: #bfe4f6;
        }

        tr:first-child td:first-child {
            border-top-left-radius: .8rem;
        }

        tr:first-child td:last-child {
            border-top-right-radius: .8rem;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: .8rem;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: .8rem;
        }
    }
}


.clinician-summary-table {
    width: 100%;

    thead {
        tr {
            line-height: 1;

            th {
                font-family: "Poppins", sans-serif;
                font-size: 1.4rem;
                font-weight: 700;
                color: #101010;
                padding-bottom: 1.4rem;

                &:first-child {
                    text-align: left;
                    padding-left: 2rem;
                }
            }
        }
    }

    tbody {
        td {
            font-family: "Poppins", sans-serif;
            font-size: 1.4rem;
            font-weight: 400;
            color: #303030;
            height: 4rem;
            background-color: #e9edf2;
            text-align: center;

            @include respond(phone) {
                white-space: nowrap;
                min-width: 15rem;
            }
        }

        tr td:not(:first-child) {
            font-size: 1.6rem;
            font-weight: 600;
            color: #ffffff;
            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        }

        tr td:first-child {
            font-weight: 500;
            color: #101010;
            background-color: #e9edf2;
            text-align: left;
            padding-left: 2rem;
        }

        tr:first-child td:first-child {
            border-top-left-radius: .8rem;
        }

        tr:first-child td:last-child {
            border-top-right-radius: .8rem;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: .8rem;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: .8rem;
        }
    }
}

.pt-low-level-bg {
    background-color: #71bf44 !important;
}

.susceptible-bg {
    background-color: #c7da2c !important;
}

.low-level-bg {
    background-color: #fdc110 !important;
}

.intermediate-bg {
    background-color: #f36f21 !important;
}

.high-level-bg {
    background-color: #ef3e38 !important;
}

.color-tags-list {
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(phone) {
        flex-direction: column;
        align-items: flex-start;
    }

    .color-tag {
        display: flex;
        align-items: center;

        &__color {
            width: 1.6rem;
            height: 1.6rem;
            border-radius: .2rem;
            margin-right: .6rem;
        }

        &__title {
            font-family: "Poppins", sans-serif;
            font-size: 1.3rem;
            font-weight: 500;
            color: #404040;
        }

        &+.color-tag {
            margin-left: 2rem;

            @include respond(phone) {
                margin-top: .5rem;
                margin-left: 0;
            }
        }
    }
}

.filled-btn {
    background: none;
    border-radius: .8rem;
    outline: none;
    border: none;
    cursor: pointer;
    color: $color-white;
    font-weight: 500;
    text-align: center;
    letter-spacing: .015rem;
    transition: .5s;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.md {
        height: 3rem;
        padding: 0 1.5rem;
        font-size: 1.6rem;
        min-width: 10rem;
    }

    &.lg {
        height: 4.5rem;
        padding: 0 1.5rem;
        font-size: 1.8rem;
    }

    &.primary {
        background-color: $color-primary;

        &:hover {
            color: $color-white;
            background-color: darken($color-primary, 10%);
        }
    }

    &.secondary {
        background-color: darken($color-secondary, 10%);

        &:hover {
            color: $color-white;
            background-color: darken($color-secondary, 20%);
        }
    }

    &.tertiary {
        background-color: $color-tertiary;

        &:hover {
            color: $color-white;
            background-color: darken($color-tertiary, 10%);
        }
    }

    &.rounded {
        border-radius: 4rem !important;
    }
}

.form-box {
    background-color: #f6f7f9;
    border: .1rem solid #f0f3f6;
    border-radius: 1.2rem;
    padding: 0 2rem 2rem;
    margin-bottom: 2.4rem;

    &__header {
        height: 3.8rem;
        display: flex;
        align-items: center;
        border-bottom: .1rem solid #e9edf2;
        margin-bottom: 1.6rem;

        .title {
            font-family: "Poppins", sans-serif;
            font-size: 1.5rem;
            font-weight: 500;
            color: #31b2eb;
            margin: 0;
            text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .required {
            font-family: "Poppins", sans-serif;
            font-size: 1.2rem;
            font-weight: 400;
            color: $color-secondary;
            margin-left: .6rem;
            text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
            white-space: nowrap;
            flex-shrink: 0;
        }

        .optional {
            font-family: "Poppins", sans-serif;
            font-size: 1.2rem;
            font-weight: 400;
            color: $color-grey;
            margin-left: .6rem;
            text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
            white-space: nowrap;
            flex-shrink: 0;
        }
    }

    &.error {
        border-color: rgba(#d71920, 50%);
    }
}

.custom-radio:checked,
.custom-radio:not(:checked) {
    position: absolute;
    left: -9999px;
}

.custom-radio:checked+label,
.custom-radio:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 1.2;
    display: inline-block;
    color: #666;
}

.custom-radio:checked+label:before,
.custom-radio:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.8rem;
    height: 1.8rem;
    border: .2rem solid #5a5a5a;
    border-radius: 100%;
    background: #fff;
}

.custom-radio:checked+label:before,
.custom-radio:checked+label:before {
    border: .2rem solid $color-secondary;
}

.custom-radio:checked+label:after,
.custom-radio:not(:checked)+label:after {
    content: '';
    width: .8rem;
    height: .8rem;
    background: $color-secondary;
    position: absolute;
    top: .5rem;
    left: .5rem;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom-radio:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.custom-radio:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.custom-form-label {
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    color: #101010;
}

.custom-select {
    font-family: "Poppins", sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    color: #101010;
    width: 100%;
    height: 3.5rem;
    background-color: $color-white;
    border: .1rem solid #e9ecef;
    border-radius: .6rem;
    padding: 0 1.2rem;
}

.custom-checkbox:checked,
.custom-checkbox:not(:checked) {
    position: absolute;
    left: -9999px;
}

.custom-checkbox:checked+label,
.custom-checkbox:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 1.2;
    display: inline-block;
    color: #666;
}

.custom-checkbox:checked+label:before,
.custom-checkbox:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.8rem;
    height: 1.8rem;
    border: .2rem solid #5a5a5a;
    border-radius: 0;
    background: #fff;
}

.custom-checkbox:checked+label:before,
.custom-checkbox:checked+label:before {
    background-color: $color-secondary;
    border: .2rem solid $color-secondary;
}

.custom-checkbox:checked+label:after,
.custom-checkbox:not(:checked)+label:after {
    content: "";
    position: absolute;
    left: 0.6rem;
    top: 0.2rem;
    width: 0.7rem;
    height: 1.1rem;
    border: solid $color-white;
    border-width: 0 0.3rem 0.3rem 0;
    transform: rotate(45deg);
    transition: all 0.2s ease;
}

.custom-checkbox:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
}

.custom-checkbox:checked+label:after {
    opacity: 1;
    transform: scale(.94) rotate(45deg);
}