$color-primary: hsl(228, 61%, 40%);
$color-secondary: #ff62b1;
$color-tertiary: #31b2eb;

$color-white: #ffffff;
$color-black: #000000;
$color-dark-grey: #151515;
$color-grey: #4d515a;

$border-radius: .8rem;
$border-radius-sm: .5rem;
$border-radius-lg: 1rem;
$border-width: .1rem;