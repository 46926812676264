@font-face {
  font-family: 'bsmartlytics';
  src: url('src/assets/fonts/bsmartlytics.eot?jdnayi');
  src: url('src/assets/fonts/bsmartlytics.eot?jdnayi#iefix') format('embedded-opentype'),
    url('src/assets/fonts/bsmartlytics.ttf?jdnayi') format('truetype'),
    url('src/assets/fonts/bsmartlytics.woff?jdnayi') format('woff'),
    url('src/assets/fonts/bsmartlytics.svg?jdnayi#bsmartlytics') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="bs-"],
[class*=" bs-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bsmartlytics' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bs-message:before {
  content: "\e900";
}

.bs-home:before {
  content: "\e901";
}

.bs-clinician:before {
  content: "\e902";
}

.bs-manager:before {
  content: "\e903";
}

.bs-smartlytics:before {
  content: "\e904";
}

.bs-trialist:before {
  content: "\e905";
}

.bs-subscription:before {
  content: "\e906";
}

.bs-dhis-2:before {
  content: "\e907";
}

.bs-age:before {
  content: "\e908";
}

.bs-gender:before {
  content: "\e909";
}

.bs-pregnancy:before {
  content: "\e90a";
}

.bs-patient-type:before {
  content: "\e90b";
}

.bs-any-mutation:before {
  content: "\e90c";
}

.bs-treatment-time:before {
  content: "\e90d";
}

.bs-mutation-class:before {
  content: "\e90e";
}

.bs-mutations:before {
  content: "\e90f";
}

.bs-medication-category:before {
  content: "\e910";
}

.bs-medication:before {
  content: "\e911";
}

.bs-menu:before {
  content: "\e912";
}

.bs-arrow-down:before {
  content: "\e913";
}

.bs-geno-type:before {
  content: "\e914";
}

.bs-infections:before {
  content: "\e915";
}

.bs-race:before {
  content: "\e916";
}

.bs-treatment-strategy:before {
  content: "\e917";
}

.bs-suppressed:before {
  content: "\e918";
}

.bs-third-party:before {
  content: "\e919";
}

.bs-suppression-admin:before {
  content: "\e91a";
}

.bs-survey-admin:before {
  content: "\e91b";
}

.bs-subscription-admin:before {
  content: "\e91c";
}

.bs-user-admin:before {
  content: "\e91d";
}

.bs-general-admin:before {
  content: "\e91e";
}